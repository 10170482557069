.ql-toolbar.ql-snow {
  border: 0;
  background-color: #dfe3e8;
  border-top-right-radius: 8px;
  border-top-left-radius: 8px;
}

.ql-container.ql-snow {
  border: 0;
  border-bottom-right-radius: 8px;
  border-bottom-left-radius: 8px;
}
.ql-editor .ql-video {
  width: 100%;
  height: 500px;
  max-height: 100%;
}

.ql-editor {
  padding: 0;
}

.ql-container .ql-editor {
  padding: 12px 15px;
}